<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="一键结账"
    width="500px"
  >
    <div style="margin-bottom: 10px;">
      <span style="color: red;font-size: 13px">
        <i
          class="iconfont icon-gantanhao"
          style="color: red;font-size: 13px;margin-right: 4px;"
        ></i
        >结账过程中请勿关闭此页面(一键结账会更新会计报表)
      </span>
    </div>
    <el-table :data="list" border :maxHeight="contentStyleObj">
      <!-- <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column> -->
      <el-table-column property="period" label="账期" width="200">
        <template #default="scope">
          <span
            style="cursor: pointer;text-decoration: underline;"
            @click="goPeriod(scope.row)"
            v-if="scope.row.period != currentPeriod"
            >{{ scope.row.period }}</span
          >
          <span v-else>{{ scope.row.period }}</span>
        </template>
      </el-table-column>
      <el-table-column property="status" label="结账状态">
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.status">
            <i class="iconfont icon-duihao"></i> <span> 结账成功</span>
          </div>
          <div class="item_icon" v-else-if="scope.row.status == false">
            <i class="iconfont icon-cuowu"></i> <span> 结账失败</span>
          </div>
          <div class="item_icon" v-else>
            <i class="iconfont icon-gantanhao"></i> <span> 未结账</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="settleAccount"
          v-loading="isLoading"
          :disabled="isLoading"
        >
          结账本期
        </el-button>
        <el-button
          type="success"
          size="small"
          @click="startTask(0)"
          v-loading="isLoading"
          :disabled="isLoading"
          plain
        >
          一键结账
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getCheckOutPeriod, checkOut } from "@/api/carryover";
import { updatePeriod } from "@/api/login";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      list: [],
      isLoading: false,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      period: "",
      contentStyleObj: {},
      currentPeriod:this.$store.getters["user/comInfo"].period
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(260);
  },
  methods: {
    init(period) {
      this.period = period;
      getCheckOutPeriod({
        period: this.period,
      }).then((res) => {
        if (res.data.data.period.length == 1) {
          this.$emit("carryOne");
        } else {
          let arr = [];
          this.dialogVisible = true;
          res.data.data.period.map((v) => {
            arr.push({
              period: v,
              status: undefined,
            });
          });
          this.list = arr;
        }
      });
    },
    startTask(option) {
      this.isLoading = true;
      let i = 0;
      i += option;
      if (i < this.list.length) {
        this.send(this.list[i].period, i);
      } else {
        this.$qzfMessage("一键结账成功");
        this.$emit("updatePeriod");
        this.dialogVisible = false;
        this.isLoading = false;
      }
    },
    send(period, index) {
      let param = {
        comId: this.comId,
        period: period,
        type:1
      };
      checkOut([param]).then((res) => {
        if (res.data.msg == "success") {
          if (res.data.data.status) {
            this.list[index].status = true;
            this.startTask(index + 1);
            // if(this.period = period){
            //   this.$emit("success", period, res.data.data);
            // }
          } else {
            this.$message({
              type: "error",
              message: period + "期结账失败",
            });
            this.$emit("updatePeriod");
            this.$emit("success", period, res.data.data);
            this.isLoading = false;
            this.list[index].status = false;
          }
        }
      });
    },
    settleAccount() {
      this.dialogVisible = false;
      this.$emit("carryOne");
      // this.isLoading = true;
      // let param = {
      //   comId: this.comId,
      //   period: this.period,
      // };
      // checkOut([param]).then((res) => {
      //   this.isLoading = false;
      //   if (res.data.msg == "success") {
      //     let index = 0;
      //     for (let i = 0; i < this.list.length; i++) {
      //       if (this.list[i].period == this.period) {
      //         index = i;
      //       }
      //     }
      //     if (res.data.data.status) {
      //       this.$qzfMessage("结账成功");
      //       this.list[index].status = true;
      //     } else {
      //       this.$qzfMessage("结账失败", 2);
      //       this.list[index].status = false;
      //     }
      //     this.$emit("updatePeriod");
      //     this.$emit("success", this.period, res.data.data);
      //   }
      // });
    },
    goPeriod(row) {
      this.dialogVisible = false;
      let comInfo = this.$store.getters["user/comInfo"];
      comInfo.period = row.period;
      updatePeriod({ period: row.period }).then((res) => {
        if (res.data.msg == "success") {
          comInfo.comType = res.data.data.info.comType;
          comInfo.comKj = res.data.data.info.comKj;
          comInfo.jzsy = res.data.data.info.jzsy;
          comInfo.isOld = res.data.data.info.isOld;
          this.$store.dispatch("user/setComInfo", comInfo);
          this.$store.dispatch("commons/getSubjects", comInfo.comId);
          //刷新
          this.$store.dispatch("tagsView/delAllViews"); //关闭
          this.goEtax("正常", false);
        }
      });
    },
    goEtax(e, force) {
      //为了防止重复调用 userInfo 做个判断
      if (this.$store.getters["user/model"] == e && !force) {
        this.gogogo();
        return;
      }
      this.$store.dispatch("user/setModel", e);
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then((res) => {
              this.$store.dispatch("tagsView/delAllViews");
              this.gogogo();
            });
        })
        .catch((err) => {});
    },
    gogogo() {
      this.$router.replace({
        path: "/redirects",
        query: {
          path: "/etaxHome/etaxHomeIndex",
        },
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
