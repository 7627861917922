<template>
  <div class="app-container">
    <slidePeriod
      @success="getPeriod"
      ref="slidePeriod"
      style="position: absolute; z-index: 999"
    ></slidePeriod>
    <div style="padding-left: 114px">
      <el-row>
        <el-col :span="6" v-if="!leftImg">
          <div :style="{ height: contentStyleObj }" class="leftPadding">
            <!-- 风险评测 -->
            <div
              style="height: 90%; padding: 26px; position: relative"
              v-loading="fxcpLoading"
            >
              <!-- <h6>*温馨提示：风险评测不影响做账，可不操作！</h6> -->
              <img class="shield" :src="imgUrlN" v-if="jzLoading" />
              <img class="circle" :src="imgUrlW" v-if="jzLoading" />
              <span class="fengxian">风险评测</span>
              <img class="shield3" :src="imgUrlQ" alt="" v-if="!jzLoading" />
              <div class="top_button2">
                <qzf-button
                  button_code="jz_jzsy"
                  type="primary"
                  v-if="this.jzsyWord"
                  size="medium"
                  @success="gotoCarryForward()"
                  plain
                  style="width:129px;margin-left:0"
                >
                  <el-icon><Coin /></el-icon> <span> 结转损益</span>
                </qzf-button>
                <qzf-button
                  button_code="jz_cxjzsy"
                  type="primary"
                  v-if="!this.jzsyWord"
                  size="medium"
                  @success="gotoCarryForward()"
                  plain
                  style="width:129px;margin-left:0"
                >
                  <el-icon><Coin /></el-icon> <span> 重新结转损益</span>
                </qzf-button>
                <div v-if="settleStatus != ''" style="position: relative;">
                  <qzf-button
                    button_code="jz_fjz"
                    jz="false"
                    v-if="settleStatus != ''"
                    type="danger"
                    size="medium"
                    @success="settlefan()"
                    plain
                    style="width:129px;margin-left:0;margin-top:20px"
                  >
                    <el-icon><Money /></el-icon><span> 反结账</span>
                  </qzf-button>
                  <span class="next" @click="toNextPeriod">下一账期</span>
                </div>
                <qzf-button
                  button_code="jz_jz"
                  jz="false"
                  v-else
                  type="danger"
                  size="medium"
                  @success="goCarryOver()"
                  plain
                  style="width:129px;margin-left:0;margin-top:20px"
                  :loading="jzBtnLoading"
                >
                  <el-icon><Money /></el-icon><span> 结账</span>
                </qzf-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" v-if="leftImg">
          <div :style="{ height: contentStyleObj }" class="leftPadding2">
            <!-- 风险评测 -->
            <div style="height: 40%; padding: 26px">
              <!-- <h6>*温馨提示：风险评测不影响做账，可不操作！</h6> -->
              <img class="shield" :src="imgUrlN" />
              <img class="circle" :src="imgUrlW" v-if="jzLoading" />
              <img class="circle2" :src="imgUrlW" v-if="!jzLoading" />

              <span class="fengxian3" v-if="this.titleWOrd == '结账'">{{
                titleWOrd
              }}</span>
              <span class="fengxian4" v-else-if="this.titleWOrd == '反结账'">{{
                titleWOrd
              }}</span>

              <span
                class="fengxian2"
                v-else-if="this.titleWOrd == '结转损益'"
                >{{ titleWOrd }}</span
              >
              <span class="fengxian5" v-else>{{ titleWOrd }}</span>

              <div class="top_button3">
                <qzf-button
                  jz="false"
                  button_code="jz_jzsy"
                  type="primary"
                  v-if="this.jzsyWord"
                  size="medium"
                  @success="gotoCarryForward()"
                  plain
                  style="width:129px;margin-left:0;"
                >
                  <el-icon><Coin /></el-icon> <span> 结转损益</span>
                </qzf-button>
                <qzf-button
                  jz="false"
                  button_code="jz_cxjzsy"
                  type="primary"
                  v-if="!this.jzsyWord"
                  size="medium"
                  @success="gotoCarryForward()"
                  plain
                  style="width:129px;margin-left:0"
                >
                  <el-icon><Coin /></el-icon> <span> 重新结转损益</span>
                </qzf-button>
                <div v-if="settleStatus != ''" style="position: relative;">
                  <qzf-button
                  button_code="jz_fjz"
                  jz="false"
                  type="danger"
                  size="medium"
                  @success="settlefan()"
                  plain
                  style="width:129px;margin-left:0;margin-top:20px"
                  >
                    <el-icon><Money /></el-icon><span> 反结账</span>
                  </qzf-button>
                  <span class="next" @click="toNextPeriod">下一账期</span>
                </div>
                <qzf-button
                  button_code="jz_jz"
                  jz="false"
                  v-else
                  type="danger"
                  size="medium"
                  @success="goCarryOver()"
                  plain
                  style="width:129px;margin-left:0;margin-top:20px"
                  :loading="jzBtnLoading"
                >
                  <el-icon><Money /></el-icon><span> 结账</span>
                </qzf-button>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 结账的list -->
        <el-col :span="18">
          <!-- <div class="titleSpan ">
            <div class="titleLeft">
              期末处理
            </div>
            <div>
              <el-button
                type="primary"
                class="button"
                size="small"
                @click="beVoucher"
                v-loading="btnLoading"
                plain
                :disabled="!$buttonStatus('jz_scpz')"
                >一键生成凭证</el-button
              >
            </div>
          </div> -->

          <!-- <div class="list_div">
            <div class="div_box">
              <div class="check_style">
                <el-checkbox v-model="checkedQdpz"></el-checkbox>
              </div>
              <div class="cloumn_div">
                <span class="title_span">清单凭证</span>
                <div class="line_div"></div>
                <div class="under_div">
                  <div class="span_div">
                    <el-tooltip
                      placement="top"
                      :disabled="!qdpzInfo.errLog"
                      :content="qdpzInfo.errLog"
                      effect="dark"
                    >
                      <div>
                        <i :class="filterIcon(qdpzInfo.status)"></i>
                        <span>
                          {{ filterStatus(qdpzInfo.status) }}
                          <span v-if="qdpzInfo.status == 1"
                            >//&nbsp;{{ qdpzInfo.amount }}</span
                          >
                        </span>
                        <i
                          class="iconfont icon-wenhao"
                          style="color:red;margin-left: 4px;"
                          v-if="qdpzInfo.errLog"
                        >
                        </i>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="under_div_right">
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  style="margin-top: 45px;"
                  @click="oneKeyQdpz"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_box">
              <div class="check_style">
                <el-checkbox v-model="checkedSalary"></el-checkbox>
              </div>
              <div class="cloumn_div">
                <span class="title_span">计提/发放工资</span>
                <div class="line_div"></div>
                <div class="under_div">
                  <div class="span_div">
                    <el-tooltip
                      placement="top"
                      :disabled="!salary.errLog"
                      :content="salary.errLog"
                      effect="dark"
                    >
                      <div>
                        <i :class="filterIcon(salary.status)"></i>
                        <span> {{ filterStatus(salary.status) }}</span>
                        <i
                          class="iconfont icon-wenhao"
                          style="color:red;margin-left: 4px;"
                          v-if="salary.errLog"
                        >
                        </i>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="under_div_right">
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  style="margin-top: 45px;"
                  @click="oneKeySalary"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_box">
              <div class="check_style">
                <el-checkbox v-model="checkedAsset"></el-checkbox>
              </div>
              <div class="cloumn_div">
                <span class="title_span">资产折旧</span>
                <div class="line_div"></div>
                <div class="under_div">
                  <div class="span_div">
                    <el-tooltip
                      placement="top"
                      :disabled="!asset.errLog"
                      :content="asset.errLog"
                      effect="dark"
                    >
                      <div>
                        <i :class="filterIcon(asset.status)"></i>
                        <span> {{ filterStatus(asset.status) }}</span>
                        <i
                          class="iconfont icon-wenhao"
                          style="color:red;margin-left: 4px;"
                          v-if="asset.errLog"
                        >
                        </i>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="under_div_right">
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  style="margin-top: 45px;"
                  @click="oneKeyAsset"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_boxs">
              <div class="check_style">
                <el-checkbox v-model="checkedYf"></el-checkbox>
              </div>
              <div class="column2">
                <span class="title_span">结转研发支出</span>
                <div class="line_div"></div>
                <div class="div2">
                  <el-tooltip
                    placement="top"
                    :disabled="!yf.errLog"
                    effect="dark"
                    :content="yf.errLog"
                  >
                    <div>
                      <i :class="filterIcon(yf.status)"></i>
                      <span>
                        {{ filterStatus(yf.status) }}/&nbsp;{{
                          yf.amount
                        }}</span
                      >
                      <i
                        class="iconfont icon-wenhao"
                        style="color:red;margin-left: 4px;"
                        v-if="yf.errLog"
                      >
                      </i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div class="under_div_right">
                <div
                  class="span"
                  style="margin-top:0"
                  @click="getSetInfo('yfjz')"
                >
                  科目设置
                </div>
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  @click="jzInfo('yfjz')"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_boxs">
              <div class="check_style">
                <el-checkbox v-model="checkedSchs"></el-checkbox>
              </div>
              <div class="column2">
                <span class="title_span">领料加工</span>
                <div class="line_div"></div>
                <div class="div2">
                  <el-tooltip
                    placement="top"
                    :disabled="!schs.errLog"
                    :content="schs.errLog"
                    effect="dark"
                  >
                    <div>
                      <i :class="filterIcon(schs.status)"></i>
                      <span> {{ filterStatus(schs.status) }}</span>
                      <i
                        class="iconfont icon-wenhao"
                        style="color:red;margin-left: 4px;"
                        v-if="schs.errLog"
                      >
                      </i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div class="under_div_right">
                <div
                  class="span"
                  style="margin-top:0;"
                  @click="getSetInfo('schs')"
                >
                  比例设置
                </div>
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  @click="jzSchs"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_boxs">
              <div class="check_style">
                <el-checkbox v-model="checkedMyhs"></el-checkbox>
              </div>
              <div class="column2">
                <span class="title_span">结转销售成本</span>
                <div class="line_div"></div>
                <div class="div2">
                  <el-tooltip
                    placement="top"
                    :disabled="!myhs.errLog"
                    :content="myhs.errLog"
                    effect="dark"
                  >
                    <div>
                      <i :class="filterIcon(myhs.status)"></i>
                      <span> {{ filterStatus(myhs.status) }}</span>
                      <i
                        class="iconfont icon-wenhao"
                        style="color:red;margin-left: 4px;"
                        v-if="myhs.errLog"
                      >
                      </i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div class="under_div_right">
                <div
                  class="span"
                  style="margin-top:0;"
                  @click="getSetInfo('myhs')"
                >
                  比例设置
                </div>
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  @click="jzMyhs"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_box">
              <div class="check_style">
                <el-checkbox v-model="checkedJt"></el-checkbox>
              </div>
              <div class="cloumn_div">
                <span class="title_span">计提税金</span>
                <div class="line_div"></div>
                <div class="under_div">
                  <div class="span_div">
                    <el-tooltip
                      placement="top"
                      :disabled="!jt.errLog"
                      :content="jt.errLog"
                      effect="dark"
                    >
                      <div>
                        <i :class="filterIcon(jt.status)"></i>
                        <span> {{ filterStatus(jt.status) }}</span>
                        <i
                          class="iconfont icon-wenhao"
                          style="color:red;margin-left: 4px;"
                          v-if="jt.errLog"
                        >
                        </i>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="under_div_right">
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  style="margin-top: 45px;"
                  @click="oneKeyTax"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
            <div class="div_boxs">
              <div class="check_style">
                <el-checkbox v-model="checkedWjzzs"></el-checkbox>
              </div>
              <div class="column2">
                <span class="title_span">结转未交增值税</span>
                <div class="line_div"></div>
                <div class="div2">
                  <el-tooltip
                    placement="top"
                    :disabled="!wjzzs.errLog"
                    :content="wjzzs.errLog"
                    effect="dark"
                  >
                    <div>
                      <i :class="filterIcon(wjzzs.status)"></i>
                      <span>
                        {{ filterStatus(wjzzs.status) }}/&nbsp;{{
                          wjzzs.amount
                        }}</span
                      >
                      <i
                        class="iconfont icon-wenhao"
                        style="color:red;margin-left: 4px;"
                        v-if="wjzzs.errLog"
                      >
                      </i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <div class="under_div_right">
                <div
                  class="span"
                  style="margin-top:0;"
                  @click="getSetInfo('wjzzsjz')"
                >
                  科目设置
                </div>
                <el-button
                  type="primary"
                  size="small"
                  class="button_el"
                  @click="jzInfo('wjzzsjz')"
                  plain
                  :disabled="!$buttonStatus('jz_scpz')"
                  >生成凭证</el-button
                >
              </div>
            </div>
          </div> -->
          <!--结账的list  -->
          <div
            style="margin-left: 10px;width: 99%;"
            v-if="!leftImg && this.jzsyWord"
          >
            <el-tabs type="border-card">
              <el-tab-pane label="风险监测">
                <el-scrollbar :height="contentStyleObj1">
                  <div class="main_list">
                    <div class="each_info">
                      <span class="info_title"
                        ><i class="iconfont icon-pingzheng1"></i>
                        资产类科目余额</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qdpz"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <span class="info_title" style="margin-top: 30px;"
                        ><i class="iconfont icon-shuiwubaobiao1"></i>
                        期末结转</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.sjjt"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                    <div class="each_info" style="margin-left: 10px;">
                      <span class="info_title"
                        ><i class="iconfont icon-yewushuju1"></i>
                        其他异常检测</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qtxm"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 结转损益的list -->
          <div
            style="margin-left: 10px;width: 99%;"
            v-if="!leftImg && !this.jzsyWord"
          >
            <el-tabs type="border-card">
              <el-tab-pane label="风险监测">
                <el-scrollbar :height="contentStyleObj1">
                  <div class="main_list">
                    <div class="each_info">
                      <span class="info_title"
                        ><i class="iconfont icon-pingzheng1"></i>
                        资产类科目余额</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qdpz"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <span class="info_title" style="margin-top: 30px;"
                        ><i class="iconfont icon-shuiwubaobiao1"></i>
                        期末结转</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.sjjt"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                    <div class="each_info" style="margin-left: 10px;">
                      <span class="info_title"
                        ><i class="iconfont icon-yewushuju1"></i>
                        其他异常检测</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qtxm"
                          :key="item"
                          class="each_title"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 结账list -->
          <div style="margin-left: 10px;width: 99%;" v-if="leftImg">
            <el-tabs type="border-card">
              <el-tab-pane label="风险监测">
                <el-scrollbar :height="contentStyleObj1">
                  <div class="main_list">
                    <div class="each_info">
                      <span class="info_title"
                        ><i class="iconfont icon-pingzheng1"></i>
                        资产类科目余额</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qdpz"
                          :key="item"
                          class="each_hint"
                        >
                          <div class="hint_title">
                            {{
                              item.match(":")
                                ? item.substring(0, item.indexOf(":"))
                                : item
                            }}
                          </div>
                          <div v-if="item.match('错误')" class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":错误") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-cuowu"></i>
                          </div>

                          <div
                            v-else-if="item.match('提示')"
                            class="info_right"
                          >
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":提示") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c"></i>
                          </div>
                          <div v-else class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.match(":")
                                  ? item.substring(
                                      item.indexOf(":") + 1,
                                      item.length
                                    )
                                  : ""
                              }}</span>
                            </div>
                            <i
                              class="iconfont icon-duihao"
                              v-if="item.match(':')"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <span class="info_title" style="margin-top: 30px;"
                        ><i class="iconfont icon-shuiwubaobiao1"></i>
                        期末结转</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.sjjt"
                          :key="item"
                          class="each_hint"
                        >
                          <div class="hint_title">
                            {{
                              item.match(":")
                                ? item.substring(0, item.indexOf(":"))
                                : item
                            }}
                          </div>
                          <div v-if="item.match('错误')" class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":错误") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-cuowu"></i>
                          </div>
                          <div
                            v-else-if="item.match('提示')"
                            class="info_right"
                          >
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":提示") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c"></i>
                          </div>
                          <div v-else class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.match(":")
                                  ? item.substring(
                                      item.indexOf(":") + 1,
                                      item.length
                                    )
                                  : ""
                              }}</span>
                            </div>
                            <i
                              class="iconfont icon-duihao"
                              v-if="item.match(':')"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="each_info" style="margin-left: 10px;">
                      <span class="info_title"
                        ><i class="iconfont icon-yewushuju1"></i>
                        其他异常检测</span
                      >
                      <div v-if="this.lossList" style="margin-top: 20px;">
                        <div
                          v-for="item in this.qtxm"
                          :key="item"
                          class="each_hint"
                        >
                          <div class="hint_title">
                            {{
                              item.match(":")
                                ? item.substring(0, item.indexOf(":"))
                                : item
                            }}
                          </div>
                          <div v-if="item.match('错误')" class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":错误") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-cuowu"></i>
                          </div>
                          <div
                            v-else-if="item.match('提示')"
                            class="info_right"
                          >
                            <div class="hint_right">
                              <span>{{
                                item.substring(
                                  item.indexOf(":提示") + 3,
                                  item.length
                                )
                              }}</span>
                            </div>
                            <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c"></i>
                          </div>
                          <div v-else class="info_right">
                            <div class="hint_right">
                              <span>{{
                                item.match(":")
                                  ? item.substring(
                                      item.indexOf(":") + 1,
                                      item.length
                                    )
                                  : ""
                              }}</span>
                            </div>
                            <i
                              class="iconfont icon-duihao"
                              v-if="item.match(':')"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <!-- 科目设置 -->
  <el-dialog
    :close-on-click-modal="false"
    draggable
    v-model="dialogFormVisible"
    title="科目设置"
    width="30%"
    :modal="false"
    destroy-on-close
  >
    <table
      class="dialog_table"
      cellspacing="0"
      :header-cell-style="{
        background: '#eee',
        color: '#333',
        'font-size': '13px',
      }"
    >
      <tr
        style="width:40%;background: var(--themeColor,#17a2b8);color: #fff;font-size: 14px;font-weight: 600;"
      >
        <td>分录方向</td>
        <td>会计科目</td>
      </tr>
      <tr style="width:20%;">
        <td>借方</td>
        <td>
          <subject-list
            v-model:subjectId="temp.inSubjectId"
            v-model:fzhsItemId="temp.inFzhstId"
          ></subject-list>
        </td>
      </tr>
      <tr style="width:40%;border-bottom:none;">
        <td>贷方</td>
        <td>
          <subject-list
            v-model:subjectId="temp.outSubjectId"
            v-model:fzhsItemId="temp.outFzhstId"
          ></subject-list>
        </td>
      </tr>
    </table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="updateData()" size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 季度计提所得税的弹框 -->
  <el-dialog
  style="margin-top: 21%;"
    title="请选择是否计提所得税"
    v-model="sdsDialogVisible"
    width="30%"
    :before-close="handleClose" center>
    <!-- <span>这是一段信息</span> -->
    <el-checkbox v-model="this.sdsStatus">计提企业所得税</el-checkbox>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="sdsDialogVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="sdsDialog()" size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <settingDialog ref="settingDialog"></settingDialog>
  <settleAll ref="settleAll" @success="updateSettle" @updatePeriod="updatePeriodCarry" @carryOne="carryOne"/>
</template>

<script>
import {
  profitAndLoss,
  reverseCheckout,
  checkOut,
  carrOverList,
  getCarryOverInfo,
  getComSetJz,
  setComSetJz,
  jzSubject,
  zdProduceCheck,
  zdTradeCheck,
  salaryCarryOver,
  carryOverAsset,
} from "@/api/carryover.js";
import { taxCategoryList } from "../../api/invoice";
import slidePeriod from "@/components/slidePeriod/slidePeriod.vue";
import { oneKeyVoucher } from "@/api/voucher";
import { taxCreate } from "@/api/taxCalculation";
import settleAll from "./components/settleAll.vue";
import  {nextAccountPeriod} from '../../utils'
import { updatePeriod } from "@/api/login";
export default {
  name: "newSettleAccount",
  components: { slidePeriod,settleAll },

  data() {
    return {
      contentStyleObj: {},
      contentStyleObj1: {},
      settleStatus: "",
      comId: 0,
      period: this.$store.getters["user/comInfo"].period,
      lossList: [],
      list: [],
      leftImg: false,
      tableData6: [
        {
          id: 1,
          name: "结转损益",
          status: false,
        },
      ],
      fxcpLoading: false,
      titleWOrd: "结转损益",
      jzLoading: false,
      jzsyWord: true,
      imgUrlN: "https://file.listensoft.net/mnt/v2/img/nei1.png",
      imgUrlW: "https://file.listensoft.net/mnt/v2/img/wai1.png",
      imgUrlQ: "https://file.listensoft.net/mnt/v2/img/quan1.png",
      asset: {},
      jt: {},
      myhs: {},
      qdpzInfo: {},
      salary: {},
      schs: {},
      wjzzs: {},
      yf: {},
      dialogFormVisible: false,
      temp: {},
      checkedAsset: false,
      checkedJt: false,
      checkedMyhs: false,
      checkedQdpz: false,
      checkedSalary: false,
      checkedWjzzs: false,
      checkedMyhs: false,
      checkedYf: false,
      btnLoading: false,
      settingMsg: "",
      jzBtnLoading:false,
      sdsStatus:true,
      sdsDialogVisible:false,
      comInfo:this.$store.getters["user/comInfo"]
    };
  },
  created() {
    // if (window.location.hostname == "daizhang.sencha.cc") {
    //   this.imgUrlN = "https://file.listensoft.net/mnt/v2/img/nei2.png";
    //   this.imgUrlW = "https://file.listensoft.net/mnt/v2/img/wai2.png";
    //   this.imgUrlQ = "https://file.listensoft.net/mnt/v2/img/quan2.png";
    // }
    this.contentStyleObj = this.$getHeight(123);
    /// this.contentStyleObj1 = this.$getHeight(470);
    this.contentStyleObj1 = this.$getHeight(194);
    this.init();
    this.getList();
    // this.getInfo();
  },

  mounted() {},

  methods: {
    filterIcon(type) {
      if (type == 0) {
        return "iconfont icon-gantanhao";
      } else if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 2) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao gantanhao-yc";
      }
    },
    filterStatus(type) {
      if (type == 0) {
        return "未结转";
      } else if (type == 1) {
        return "已结转";
      } else if (type == 2) {
        return "无需结转";
      } else if (type == 6) {
        return "未设置结转科目";
      }
    },
    init() {
      this.settleStatus = this.$checkSettleStatus('settle');
      if (this.$store.getters["user/comInfo"].jzsy == 0) {
        this.jzsyWord = true;
        this.qdpz = [
          "库存现金",
          "银行存款",
          "原材料",
          "库存商品",
          "固定资产",
          "无形资产",
          "长期待摊费用",
        ];
        this.sjjt = [
          "计提工资",
          "资产折旧",
          "销售成本结转",
          "计提税金",
          "财务报表",
        ];
        this.qtxm = [
          "清单凭证",
          "凭证断号",
          "科目余额校验",
          "福利费超标",
          "业务招待费超标",
          "成本收入",
          "广告超标",
          "小规模500万",
          "小规模季度30万",
        ];
      } else {
        this.jzsyWord = false;
        this.qdpz = [
          "库存现金",
          "银行存款",
          "原材料",
          "库存商品",
          "固定资产",
          "无形资产",
          "长期待摊费用",
        ];
        this.sjjt = [
          "计提工资",
          "资产折旧",
          "销售成本结转",
          "计提税金",
          "财务报表",
        ];
        this.qtxm = [
          "清单凭证",
          "凭证断号",
          "科目余额校验",
          "福利费超标",
          "业务招待费超标",
          "成本收入",
          "广告超标",
          "小规模500万",
          "小规模季度30万",
        ];
      }
    },
    getList() {
      let param = {
        type: "jzsy",
        period: this.period,
      };
      carrOverList(param).then((res) => {
        if(res.data.data.list && res.data.data.list.length != 1){
          this.jzLoading = false;
          this.jzsyWord = false
          this.leftImg = true
          if(res.data.data.list){
            this.lossList = res.data.data.list
            res.data.data.list.map(v => {
              if(v.name == '资产类科目余额'){
                this.qdpz = v.errs
              }else if(v.name == '期末结转'){
                this.sjjt = v.errs
              }else if(v.name == '其他异常检测'){
                this.qtxm = v.errs
              }
            })
            console.log(this.sjjt);
            if(this.qdpz.length == 0){
                this.qdpz = ["库存现金","银行存款","原材料","库存商品","固定资产","无形资产","长期待摊费用"]
              }
              if(this.sjjt.length == 0){
                this.sjjt = ["计提工资","资产折旧","销售成本结转","计提税金","财务报表"]
              }
              if(this.qtxm.length == 0){
                this.qtxm = ["清单凭证","凭证断号","科目余额校验","福利费超标","业务招待费超标","成本收入","广告超标","小规模500万","小规模季度30万"]
              }
          } else{
            this.lossList = []
          }
        }
      });
    },
    getInfo() {
      getCarryOverInfo({
        period: this.period,
      }).then((res) => {
        if (res.data.msg == "success") {
          let data = res.data.data;
          this.asset = data.asset;
          this.checkedAsset = data.asset.status == 0 ? true : false;
          this.jt = data.jt;
          this.checkedJt = data.jt.status == 0 ? true : false;
          this.myhs = data.myhs;
          this.checkedMyhs = data.myhs.status == 0 ? true : false;
          this.qdpzInfo = data.qdpz;
          this.checkedQdpz = data.qdpz.status == 0 ? true : false;
          this.salary = data.salary;
          this.checkedSalary = data.salary.status == 0 ? true : false;
          this.schs = data.schs;
          this.checkedSchs = data.schs.status == 0 ? true : false;
          this.wjzzs = data.wjzzs;
          this.checkedWjzzs = data.wjzzs.status == 0 ? true : false;
          this.yf = data.yf;
          this.checkedYf = data.yf.status == 0 ? true : false;
        }
      });
    },
    //反结账
    settlefan() {
      this.jzLoading = true;
      this.titleWOrd = "反结账";
      this.lossList = [];
      let param = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        period: this.period,
      };
      reverseCheckout([param]).then((res) => {
        this.jzLoading = false;
        if (res.data.msg != "success") {
          return;
        }
        this.$store.dispatch("commons/InitPeriod", param.comId).then((res) => {
          this.leftImg = false;
          this.$qzfMessage("反结账成功");
          this.init();
          this.$refs.slidePeriod.getList(param);
        });
      });
    },
    //结账
    goCarryOver() {
      this.leftImg = true;
      this.titleWOrd = "结账";
      this.$refs.settleAll.init(this.period)
    },
    ///账期list只有一条
    carryOne(){
      this.leftImg = true;
      this.jzLoading = true;
      this.titleWOrd = "结账";
      this.jzBtnLoading = true
      let param = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        period: this.period,
      };
      checkOut([param]).then((res) => {
        this.jzLoading = false;
        this.jzBtnLoading = false
        if (res.data.msg == "success") {
          if (res.data.data.status) {
            this.$message({
              type: "success",
              message: "结账成功",
            });
            this.$store
              .dispatch("commons/InitPeriod", param.comId)
              .then((res) => {
                // this.init()
              });
            this.$refs.slidePeriod.getList(param);
          } else {
            this.$message({
              type: "error",
              message: "结账失败",
            });
          }
        }

        if (res.data.data.list) {
          this.lossList = res.data.data.list;
          res.data.data.list.map((v) => {
            if (v.name == "资产类科目余额") {
              this.qdpz = v.errs;
            } else if (v.name == "期末结转") {
              this.sjjt = v.errs;
            } else if (v.name == "其他异常检测") {
              this.qtxm = v.errs;
            }
          });
        } else {
          this.lossList = [];
        }
      });
    },
    //更新左侧账期数据
    updatePeriodCarry(){
      let param = {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        period: this.period,
      };
      this.$store
      .dispatch("commons/InitPeriod", param.comId)
      .then((res) => {
        // this.init()
      });
      this.$refs.slidePeriod.getList(param);
    },
    //右侧内容赋值
    updateSettle(period,data){
      if(this.period == period){
        if (data.list) {
          this.lossList = data.list;
          data.list.map((v) => {
            if (v.name == "资产类科目余额") {
              this.qdpz = v.errs;
            } else if (v.name == "期末结转") {
              this.sjjt = v.errs;
            } else if (v.name == "其他异常检测") {
              this.qtxm = v.errs;
            }
          });
        } else {
          this.lossList = [];
        }
      }
     
    },
    sdsDialog(){
      this.jzLoading = true;
      this.leftImg = true;
      this.titleWOrd = "结转损益";
      this.sdsDialogVisible = false
      this.jzsyLast(this.sdsStatus)
    },
    // 结转损益
    gotoCarryForward() {
      // let comInfo = this.$store.getters["user/comInfo"];
      // 所得税季度报的计提
      // if (this.period.slice(4) == "03" || this.period.slice(4) == "06"|| this.period.slice(4) == "09" || this.period.slice(4) == "12"){
        // this.sdsDialogVisible = true
      // }else{
        this.sdsStatus = false
        this.jzsyLast(this.sdsStatus)
        this.jzLoading = true;
        this.leftImg = true;
        this.titleWOrd = "结转损益";
      // }
      
    },
    jzsyLast(sdsStatus){
      profitAndLoss({ period: this.period,sdsStatus:sdsStatus }).then((res) => {
        this.jzLoading = false;
        if (res.data.msg == "success") {
          if (res.data.data.status) {
            this.$message({
              type: "success",
              message: "结转损益成功",
            });
            this.comInfo.jzsy = 1;
            this.$store.dispatch("user/setComInfo", this.comInfo);
            this.titleWOrd = "重新结转损益";
            this.jzsyWord = false;
          } else {
            this.$message({
              type: "error",
              message: "结转损益失败",
            });
          }
        } else {
          if (
            res.data.msg == "未设置本年利润科目" ||
            res.data.msg == "未设置未分配利润科目"
          ) {
            this.settingMsg = res.data.msg;
            let route = "/setting/habbitSettingBook";
            this.$refs.settingDialog.getInit(this.settingMsg, route);
          }
        }
        if(res.data.data.list){
          this.lossList = res.data.data.list
          res.data.data.list.map(v => {
            if(v.name == '资产类科目余额'){
              this.qdpz = v.errs
            }else if(v.name == '期末结转'){
              this.sjjt = v.errs
            }else if(v.name == '其他异常检测'){
              this.qtxm = v.errs
            }
            if(this.qdpz.length == 0){
              this.qdpz = ["库存现金","银行存款","原材料","库存商品","固定资产","无形资产","长期待摊费用"]
            }
            if(this.sjjt.length == 0){
              this.sjjt = ["计提工资","资产折旧","销售成本结转","计提税金","财务报表"]
            }
            if(this.qtxm.length == 0){
              this.qtxm = ["清单凭证","凭证断号","科目余额校验","福利费超标","业务招待费超标","成本收入","广告超标","小规模500万","小规模季度30万"]
            }
          })
        } else{
          this.lossList = []
        }
      });
    },
    // 组件
    getPeriod(item) {
      //console.log(item);
      this.period = item.period;
      if (item.status == 4) {
        this.settleStatus = "本期已结账，请先进行反结账";
      } else {
        this.settleStatus = "";
      }
      //console.log(this.settleStatus);
      // this.getList();
      // this.getInfo()
    },
    //查看科目设置
    getSetInfo(type) {
      if (!this.$buttonStatus("jz_sz")) {
        this.$qzfMessage("暂无权限", 1);
        return;
      }
      let that = this;
      getComSetJz({
        name: type,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.temp = res.data.data;
          this.temp.name = type;
          if (type == "wjzzsjz" || type == "yfjz") {
            this.dialogFormVisible = true;
          } else {
            this.$prompt("请输入成本比例（%）", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputValue: this.temp.bl,
            }).then(({ value }) => {
              // 成功的操作 写在这里
              that.updateBl(value, type);
            });
          }
        }
      });
    },
    updateBl(value, type) {
      if (isNaN(Number(value))) {
        this.$qzfMessage("请输入合法数字", 1);
        return;
      }
      setComSetJz({
        name: type,
        bl: Number(value),
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
        }
      });
    },
    //修改设置
    updateData() {
      setComSetJz(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          this.dialogFormVisible = false;
        }
      });
    },
    //结转生产核算
    jzSchs() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      zdProduceCheck({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        }
      });
    },
    //结转贸易核算
    jzMyhs() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      zdTradeCheck({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        }
      });
    },
    //结转
    jzInfo(type) {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      jzSubject({
        type: type,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        }
      });
    },
    //清单凭证
    oneKeyQdpz() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      oneKeyVoucher({ type: "qingdan" }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        } else {
          if (
            res.data.msg == "未设置进项税额科目" ||
            res.data.msg == "未设置未认证进项税额科目" ||
            res.data.msg == "未设置销项税额科目" ||
            res.data.msg == "未设置费用现金结算科目" ||
            res.data.msg == "未设置现金结算科目"
          ) {
            this.settingMsg = res.data.msg;
            let route = "/setting/habbitSettingBook";
            this.$refs.settingDialog.getInit(this.settingMsg, route);
          }
        }
      });
    },
    //工资
    oneKeySalary() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      salaryCarryOver({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        } else {
          if (
            res.data.msg == "未设置生产成本科目" ||
            res.data.msg == "未设置制造费用科目" ||
            res.data.msg == "未设置劳务成本科目" ||
            res.data.msg == "未设置销售费用科目" ||
            res.data.msg == "未设置薪酬类其他科目" ||
            res.data.msg == "未设置管理费用科目" ||
            res.data.msg == "未设置代扣社保科目" ||
            res.data.msg == "未设置代扣公积金科目" ||
            res.data.msg == "未设置代扣个人所得税科目" ||
            res.data.msg == "未设置应付职工薪酬科目" ||
            res.data.msg == "未设置代扣其他科目" ||
            res.data.msg == "未设置劳务报酬贷科目" ||
            res.data.msg == "未设置劳务报酬借科目" ||
            res.data.msg == "未设置全年一次性奖金贷科目" ||
            res.data.msg == "未设置全年一次性奖金借科目" || 
            res.data.msg == "未设置经营所得贷科目" ||
            res.data.msg == "未设置经营所得借科目"
          ) {
            this.settingMsg = res.data.msg;
            let route = "/setting/habbitSettingBook";
            this.$refs.settingDialog.getInit(this.settingMsg, route);
          }
        }
      });
    },
    //资产折旧
    oneKeyAsset() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      carryOverAsset({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        }
      });
    },
    //计提税金
    oneKeyTax() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      taxCreate({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("生成成功");
          this.getInfo();
        }
      });
    },
    //一键生成凭证
    async beVoucher() {
      if (this.settleStatus != "") {
        this.$qzfMessage("本期已结账", 1);
        return;
      }
      this.btnLoading = true;
      if (this.checkedQdpz) {
        await oneKeyVoucher({ type: "qingdan" }).then((res) => {
          if (res.data.msg == "success") {
          } else {
            if (
              res.data.msg == "未设置进项税额科目" ||
              res.data.msg == "未设置未认证进项税额科目" ||
              res.data.msg == "未设置销项税额科目" ||
              res.data.msg == "未设置费用现金结算科目" ||
              res.data.msg == "未设置现金结算科目"
            ) {
              this.settingMsg = res.data.msg;
            }
          }
        });
      }
      if (this.checkedSalary) {
        await salaryCarryOver({}).then((res) => {
          if (res.data.msg == "success") {
          } else {
            if (
              res.data.msg == "未设置生产成本科目" ||
              res.data.msg == "未设置制造费用科目" ||
              res.data.msg == "未设置劳务成本科目" ||
              res.data.msg == "未设置销售费用科目" ||
              res.data.msg == "未设置薪酬类其他科目" ||
              res.data.msg == "未设置管理费用科目" ||
              res.data.msg == "未设置代扣社保科目" ||
              res.data.msg == "未设置代扣公积金科目" ||
              res.data.msg == "未设置代扣个人所得税科目" ||
              res.data.msg == "未设置应付职工薪酬科目" ||
              res.data.msg == "未设置代扣其他科目" ||
              res.data.msg == "未设置劳务报酬贷科目" ||
              res.data.msg == "未设置劳务报酬借科目" ||
              res.data.msg == "未设置全年一次性奖金贷科目" ||
              res.data.msg == "未设置全年一次性奖金借科目"
            ) {
              this.settingMsg = res.data.msg;
            }
          }
        });
      }
      if (this.checkedAsset) {
        await carryOverAsset({}).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      if (this.checkedYf) {
        await jzSubject({ type: "yfjz" }).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      if (this.checkedSchs) {
        await zdProduceCheck({}).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      if (this.checkedMyhs) {
        await zdTradeCheck({}).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      if (this.checkedJt) {
        await taxCreate({}).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      if (this.checkedWjzzs) {
        await jzSubject({ type: "wjzzsjz" }).then((res) => {
          if (res.data.msg == "success") {
          }
        });
      }
      this.btnLoading = false;
      // 获取未设置科目的
      if (this.settingMsg) {
        let route = "/setting/habbitSettingBook";
        this.$refs.settingDialog.getInit(this.settingMsg, route);
      }
      this.getInfo();
    },
    //跳转下一账期
    toNextPeriod(){
      let nextPeriod = nextAccountPeriod(this.period)
      let comInfo = this.$store.getters["user/comInfo"];
      comInfo.period = nextPeriod;
      updatePeriod({ period: nextPeriod }).then((res) => {
        if (res.data.msg == "success") {
          comInfo.comType = res.data.data.info.comType;
          comInfo.comKj = res.data.data.info.comKj;
          comInfo.jzsy = res.data.data.info.jzsy;
          comInfo.isOld = res.data.data.info.isOld;
          this.$store.dispatch("user/setComInfo", comInfo);
          this.$store.dispatch("commons/getSubjects", comInfo.comId);
          //刷新
          this.$store.dispatch("tagsView/delAllViews"); //关闭
          this.goEtax("正常", false);
        }
      });
    },
    goEtax(e, force) {
      //为了防止重复调用 userInfo 做个判断
      if (this.$store.getters["user/model"] == e && !force) {
        this.gogogo();
        return;
      }
      this.$store.dispatch("user/setModel", e);
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then((res) => {
              this.$store.dispatch("tagsView/delAllViews");
              this.gogogo();
            });
        })
        .catch((err) => {});
    },
    gogogo() {
      this.$router.replace({
        path: "/redirects",
        query: {
          path: "/etaxHome/etaxHomeIndex",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_box {
  .iconfont icon-cuowu {
    color: #f56c6c;
  }
  .iconfont icon-duihao {
    color: #67c23a;
  }
  .iconfont icon-cuowu {
    color: #e6a23c;
  }
  .item {
    // display: flex;
    text-align: center;
    // flex-direction:row;
    // justify-content : flex-start;
    // border-bottom: 1px dashed #ccc;
    i {
      // line-height: 36px;
      font-size: 13px;
    }
    span {
      display: inline-block;
      font-size: 13px;
      color: #666;
      // line-height: 36px;
      margin-left: 4px;
    }
  }
}
.top_button {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.leftPadding {
  padding: 20px;
  background: #f2f9fb;
}

.leftPadding2 {
  padding: 20px;
  position: relative;
  background: #f2f9fb;
}
.circle {
  animation-duration: 1s;
  animation-name: mymove;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  // animation-fill-mode: both;
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.circle2 {
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 230px;
  height: 230px;
}
@keyframes mymove {
  from {
    width: 230px;
    height: 230px;
    transform: rotate(0deg);
  }
  to {
    width: 230px;
    height: 230px;
    transform: rotate(360deg);
  }
}

// @keyframes mymove2 {
//   0% {
//     width: 200px;
//     height:200px
//   }
//   100% {
//     width: 210px;
//     height: 210px;
//   }
// }
.shield {
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 140px;
  height: 140px;
}
.shield3 {
  position: absolute;
  top: -222px;
  // top: -131px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 240px;
  height: 240px;
}
.top_button2 {
  position: absolute;
  top: 512px;
  bottom: 0;
  left: 25px;
  right: 0;
  margin: auto;
  width: 250px;
  height: 130px;
  text-align: center;
}

.top_button3 {
  position: absolute;
  top: 512px;
  bottom: 0;
  left: 9px;
  right: 0;
  margin: auto;
  width: 252px;
  height: 130px;
  text-align: center;
}
.fengxian {
  position: absolute;
  top: -179px;
  // top: -96px;
  left: 10px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
}
.fengxian2 {
  position: absolute;
  top: -191px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}
.fengxian5 {
  position: absolute;
  top: -208px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 18px;
  color: var(--themeColor, #17a2b8);
}
.fengxian3 {
  position: absolute;
  top: -165px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}
.fengxian4 {
  position: absolute;
  top: -171px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}

.descripBody {
  padding: 12px;
}
.titleSpan {
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.bodyTitle {
  border-top: 1px solid var(--themeColor, #17a2b8);
}
.cell-item {
  font-size: 13px;
}
.div {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.title_div {
  background-color: #13a2b8;
  width: 5px;
  height: 20px;
  margin-left: 10px;
}
.title_p {
  font-size: 30px;
}

.button {
  //margin-right: 32px;
}
.list_div {
  width: 99%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border: 1px solid; */
  margin-left: 10px;
  // margin-top: 10px;
}
.div_box {
  border: 1px solid #e6e6e6;
  display: flex;
  width: 24%;
  height: 100px;
  margin-bottom: 10px;
}
.cloumn_div {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin-left: 10px;
  width: 55%;
}
.title_span {
  font-size: 13px;
  font-weight: 550;
}
.line_div {
  width: 60px;
  height: 3px;
  background-color: #13a2b8;
  margin-top: 5px;
}
.under_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  //width: 180px;
}
.span_div {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  cursor: pointer;
}
.span_div span {
  font-size: 12px;
}
.h_div {
  display: flex;
  justify-content: space-between;
}
.h_div2 {
  display: flex;
}
.span {
  font-size: 13px;
  font-weight: 570;
  color: #13a2b8;
  margin-top: 7px;
  //margin-left: 30px;
  text-align: right;
  cursor: pointer;
}
.title_div2 {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-left: 20px;
}
.div_boxs {
  border: 1px solid #e6e6e6;
  display: flex;
  width: 24%;
  height: 100px;
  margin-bottom: 10px;
}
.column2 {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 6px;
  width: 55%;
}
.spanes {
  font-size: 12px;
}
.div2 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-size: 12px;
  //width: 80px;
  cursor: pointer;
}
.under_div_right {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-right: 5px;
  // margin-left: 20px;
}
.button_el {
  // margin-left: 20px;
  margin-top: 30px;
}
.titleLeft::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.titleLeft {
  padding-left: 12px;
  color: #333;
}
.check_style {
  padding-left: 5px;
}
.main_list {
  display: flex;
  //justify-content: space-between;
}
.each_info {
  width: 49%;
}
.each_info:first-child {
  padding-right: 5px;
  border-right: 1px solid #efefef;
}
.info_title {
  width: 99%;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 10px;
  display: inline-block;
  // margin-top: 10px;
  border-bottom: 1px solid #efefef;
}
.each_title {
  width: 98%;
  padding: 7px 0;
  border-bottom: 1px solid #efefef;
  font-size: 12px;
  margin-left: 1%;
}
.each_title::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.each_hint {
  width: 98%;
  padding: 7px 0;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
}
.hint_title::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.iconfont {
  margin-left: 4px;
  font-size: 13px;
}
.dialog_table {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-bottom: 0;
  tr {
    line-height: 32px;
    height: 32px;
  }
  td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    font-size: 14px;
  }
  td:last-child {
    border-right: 0;
  }
}
.hint_title {
  width: 22%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 12px;
}
.hint_right {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 12px;
}
:deep(.el-button) {
  font-size: 12px !important;
}
.info_right {
  width: 78%;
  display: flex;
  align-items: center;
}

.next{
  cursor: pointer;
  position: absolute;
  top: 28px;
  font-size: 12px;
  color: #3c6ff2;
  // margin-left: 5px;
  right: 0;
  text-decoration-line: underline;
}
</style>
<style>
.my-label {
  width: 150px;
}
</style>
